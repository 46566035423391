import { IUseFetchDetailError } from "@/hook/fetch/interface";
import { SeoChangeLanguage } from "@/utils/seo/seo";
import { IStore } from "../../types/StoreType";
import { useFetch } from "@/hook/fetch/fetch";
import { UserTypeCarts } from "../User/type";
import { LangTypeOperation } from "./type";
import { Module } from "vuex";

export const LanguageTypeOperation: Module<LangTypeOperation, IStore> = {
  state: {
    ui_language: "English",
    language: {},
    allLang: [null, "English", "Finland", "Swedish"],
  },
  getters: {
    getUserUiLang(state) {
      SeoChangeLanguage(state.ui_language);
      return state.ui_language;
    },
    getAllLanguage(state) {
      return state.language;
    },
  },
  mutations: {
    addLanguage(state, payload: string) {
      if (!payload) return false;
      state.language = { ...state.language, [payload]: payload };
    },
    addUiLanguage(state, payload: string) {
      if (!payload) return false;
      state.ui_language = state.allLang.find(
        (_) => _?.toUpperCase() === payload?.toUpperCase()
      );
    },
  },
  actions: {
    addLanguage({ commit }, payload) {
      commit("addLanguage", payload);
    },
    async updateLanguage(store, payload) {
      const userId = store.getters.getUserData.id;
      if (!userId || !payload) return;
      const lang = store.state.allLang
        .find((_, i) => i === payload)
        ?.toUpperCase();
      const { data, isError } = await useFetch<
        UserTypeCarts,
        IUseFetchDetailError
      >(
        process.env.VUE_APP_SERVER + "api/users/" + userId + "/",
        "PATCH",
        true,
        {
          ui_language: lang,
        }
      );

      if (payload) {
        switch (payload) {
          case 1: {
            store.state.ui_language = "English";
            break;
          }
          case 2: {
            store.state.ui_language = "Finland";
            break;
          }
          case 3: {
            store.state.ui_language = "Swedish";
            break;
          }
        }
      }

      if (data.value && !isError.value) {
        store.commit("addUiLanguage", data.value?.ui_language);
      }
    },
  },
};
